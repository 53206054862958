












































import Vue from "vue";
import { FOV } from "rot-js";
import { arrayCountTrue } from "./lib/utils";
import { Field } from "./types/field";
import { FieldColors } from "./lib/consants";

let ctx: CanvasRenderingContext2D;
let fields: Field[][];
let lights: boolean[][];

export default Vue.extend({
  data() {
    return {
      ctx: (null as unknown) as CanvasRenderingContext2D,
      cellSizeX: 20,
      cellSizeY: 20,
      horizontalCells: 30,
      verticalCells: 20,
      previousMousePosition: ""
    };
  },

  mounted() {
    const canvas = document.getElementById("canvas") as HTMLCanvasElement;
    this.ctx = (canvas.getContext("2d") as unknown) as CanvasRenderingContext2D;
    this.ctx.canvas.width = this.cellSizeX * this.horizontalCells;
    this.ctx.canvas.height = this.cellSizeY * this.verticalCells;

    lights = this.fillGrid(false);

    this.clearAll();
  },

  methods: {
    // create multidimensional array and fill it with some value
    fillGrid<T>(defaultValue: T) {
      const grid: T[][] = [];

      for (let x = 0; x < this.horizontalCells; x++) {
        grid.push([]);
        for (let y = 0; y < this.verticalCells; y++) {
          grid[x].push(defaultValue);
        }
      }
      return grid;
    },

    clearAll() {
      fields = this.fillGrid(Field.GROUND);
      this.redrawCanvas();
    },

    // create 'shadow' on mouse move
    mouseMove(e: MouseEvent) {
      var [x, y] = this.getCoords(e);
      const mousePosition = `${x},${y}`;

      if (mousePosition !== this.previousMousePosition) {
        this.previousMousePosition = mousePosition;
        lights = this.calculateFOV(x, y, [Field.GROUND]);
        this.redrawCanvas();
      }
    },

    // on left mouse click create or build wall
    leftClickToggleWall(e: MouseEvent) {
      var [x, y] = this.getCoords(e);

      fields[x][y] = fields[x][y] === Field.WALL ? Field.GROUND : Field.WALL;
      this.redrawCanvas();
    },

    // on left mouse click create or build base
    rightClickToggleBase(e: MouseEvent) {
      var [x, y] = this.getCoords(e);

      fields[x][y] = fields[x][y] === Field.BASE ? Field.GROUND : Field.BASE;
      this.redrawCanvas();
    },

    markCoveredSpots() {
      const basesFOV: Array<boolean[][]> = [];

      fields.forEach((row, x) =>
        row.forEach((field, y) => {
          // find all BASE spots
          if (field === Field.BASE) {
            // for each BASE find it's FOV
            basesFOV.push(this.calculateFOV(x, y, [Field.GROUND, Field.COVERED]));
          }
        })
      );

      fields.forEach((row, x) =>
        row.forEach((field, y) => {
          // previously COVERED fileds mark as GROUND
          if (fields[x][y] === Field.COVERED) {
            fields[x][y] = Field.GROUND;
          }
          // if any BASE has FOV over some field
          // mark that field as COVERED
          basesFOV.forEach(base => {
            if (base[x][y] === true) {
              fields[x][y] = Field.COVERED;
            }
          });
        })
      );
    },

    // locate best position for next base
    findBestSpotForBase() {
      let maxCoveredSpots = 0;
      let bestSpot: [number, number] | null = null;

      for (let x = 0; x < this.horizontalCells; x++) {
        for (let y = 0; y < this.verticalCells; y++) {
          if (fields[x][y] !== Field.WALL && fields[x][y] !== Field.BASE) {
            const fovArray = this.calculateFOV(x, y, [Field.GROUND]);
            const coveredSpots = fovArray.reduce(arrayCountTrue, 0);

            if (coveredSpots > maxCoveredSpots) {
              maxCoveredSpots = coveredSpots;
              bestSpot = [x, y];
            }
          }
        }
      }

      if (bestSpot) {
        fields[bestSpot[0]][bestSpot[1]] = Field.BASE;

        this.redrawCanvas();
      }
    },

    // find field of view from specific postion
    // if third argumet is used those types of fields will not be allowed
    calculateFOV(x: number, y: number, allowedFields: Field[] = []) {
      const fov = this.fillGrid(false);

      let shadowCastingFunction = new FOV.PreciseShadowcasting(this.isFlyArea, {
        topology: 8
      });
      // in order to optimize rot-js FOV.compute function we need to limit
      // calculation by calcualting max radius
      const maxRadius = Math.max(x, this.horizontalCells - x, y, this.verticalCells - y);

      shadowCastingFunction.compute(x, y, maxRadius, (x, y, _, visibility) => {
        if (x >= 0 && x < this.horizontalCells && y >= 0 && y < this.verticalCells) {
          if (visibility === 1 && allowedFields.includes(fields[x][y])) {
            fov[x][y] = true;
          } else {
            fov[x][y] = false;
          }
        }
      });
      return fov;
    },

    // callback functon to FOV.PreciseShadowcasting
    // used to determine if some positon is obstacle or not
    isFlyArea<LightPassesCallback>(x: number, y: number) {
      if (
        x >= 0 &&
        x < this.horizontalCells &&
        y >= 0 &&
        y < this.verticalCells &&
        fields[x][y] !== Field.WALL
      )
        return true;

      return false;
    },

    // extract and calculate x,y position based from relative mouse position
    getCoords({ clientX, clientY }: MouseEvent) {
      const { offsetLeft, clientLeft, offsetTop, clientTop } = this.ctx.canvas;

      const x = clientX - offsetLeft - clientLeft;
      const y = clientY - offsetTop - clientTop;

      return [Math.floor(x / this.cellSizeX), Math.floor(y / this.cellSizeY)];
    },

    // this function will repaint the canvas
    redrawCanvas() {
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);

      this.markCoveredSpots();

      for (let x = 0; x < this.horizontalCells; x++) {
        for (let y = 0; y < this.verticalCells; y++) {
          const field = fields[x][y];

          if (lights[x][y] && fields[x][y] === Field.GROUND) {
            this.ctx.fillStyle = FieldColors[Field.LIGHT];
          } else {
            this.ctx.fillStyle = FieldColors[field];
          }

          this.ctx.fillRect(x * this.cellSizeX, y * this.cellSizeY, this.cellSizeX, this.cellSizeY);
        }
      }
    }
  }
});
